$(function() {
 const menuItemTitles = $(".menuItemTitle"),
      menuItems = $(".menuItemCon"),
      navBurger = $('.svgBurgerSettings')[0],
      mainNavCon = $("#mainNavigationCon")[0], 
      menuBackButton = $("#menuBackButton")[0];

 var mq = window.matchMedia("(min-width: 1024px)");


 const menuColors = [
"#7b33f0",
"#8432ed",
"#8d31ea",
"#9530e7",
"#9c2fe4",
"#a32ee1",
"#aa2ede",
"#b02edb",
"#b62ed9",
"#bb2fd6",
"#c030d3",
"#c531d0",
"#c933cd",
"#ce35ca",
"#d237c8",
"#d53ac5",
"#d93cc2",
"#dc3fc0",
"#e042be",
"#e245bb",
"#e549b9",
"#e84cb7",
"#ea4fb5",
"#ec53b3",
"#ef57b1",
"#f05aaf",
"#f25ead",
"#f462ac",
"#f566aa",
"#f769a9",
"#f86da8",
"#f971a7"];




$('.menuItemCon').each((i, menu) => {
  let buttons = $(menu).find('.submenu-item');
  let step = menuColors.length / buttons.length;
      $(buttons).each((ii, but) => {
        let x = Math.round(ii*step);
        $(but).attr("data-hex", menuColors[x]);
        $(but).css("background-color", menuColors[x]);
      });
});


$('.submenu-item').on("mouseover", (e) => {
  let hex = $(e.currentTarget).data("hex");
    $(e.currentTarget).css("background-color", "white");
    $(e.currentTarget).css("color", hex);
    $(e.currentTarget).find(".chevron").css("fill", hex);
});

$('.submenu-item').on("mouseout", (e) => {
  let hex = $(e.currentTarget).data("hex");
  $(e.currentTarget).css("color", "white");
    $(e.currentTarget).css("background-color", hex);
    $(e.currentTarget).find(".chevron").css("fill", "white");
});





  $(mq).on("change", (e) => {
    handleResize(e.currentTarget);
  });

  $(navBurger).on('click', () => {//768px or less only - open/close main menu 
    $('html, body').addClass("noscroll");
    $('.menuItemCon.slide-in').removeClass("slide-in");
    $('.menuItemCon.slide-out').removeClass("slide-out");
    $('#xCon').find('svg').removeClass("spinny");
    $(mainNavCon).fadeIn();
     $('.menuItemCon').delay(800).scrollTop(0);  
  });

  $("#xCon").on('click', (e) => {
    // console.log(e);
    $(e.currentTarget).find('svg').addClass("spinny");
    $('html, body').removeClass("noscroll");
     $('.menuItemCon.slide-in').removeClass("slide-in");
    $('.menuItemCon.slide-out').removeClass("slide-out");
      $(mainNavCon).fadeOut();
      $(menuBackButton).addClass("invisible");
  });

  $(menuBackButton).on('click', () => { //768px or less only - submenu back button 
    // console.log("menu back butt");
    $(menuBackButton).addClass("invisible");
    $('.menuItemCon.slide-in').addClass("slide-out");
    $('.menuItemCon.slide-in').removeClass("slide-in");
    $('.menuItemCon').delay(800).scrollTop(0);
  });



function handleResize(e) {

$(menuBackButton).addClass("invisible");
$(menuItemTitles).off();
$('.menuItem').off();

$('.menuItemCon').css("margin-top", 100);
$(".nav-background").removeClass('menu-open');
$('.menuItemTitle').removeClass("selected");
$('.menuItemCon .menuItem').removeClass("activeMenu");
$('.menuItemCon').removeClass("slide-in");






 function waitForElement(querySelector, timeout){
  return new Promise((resolve, reject)=>{
    var timer = false;
    if(document.querySelectorAll(querySelector).length) return resolve();
    const observer = new MutationObserver(()=>{
      if(document.querySelectorAll(querySelector).length){
        observer.disconnect();
        if(timer !== false) clearTimeout(timer);
        return resolve();
      }
    });
    observer.observe(document.body, {
      childList: true, 
      subtree: true
    });
    if(timeout) timer = setTimeout(()=>{
      observer.disconnect();
      reject();
    }, timeout);
  });
}

let headerHeight = $('.header').outerHeight(true)
                    + $('.announcement').outerHeight(true);

// console.log('announcement: ' + $('.announcement').outerHeight(true));
// console.log('header: ' + $('.header').outerHeight(true));
// console.log('main nav: ' + $('#mainNavigationCon').outerHeight(true));

function calculateHeaderOffset() {
  // console.log("calculating hdead offset !!!~~~~~");
  const scrollTop = $(window).scrollTop();
  // console.log("scroll top : ", scrollTop);
  let headerHeight = $('.header').outerHeight(true) + $('.announcement').outerHeight(true);
  
  if ($("#qab_background").length) {
    headerHeight += $("#qab_background").outerHeight(true);
  }

  const fullOffset = headerHeight + 50;
  const windowHeight = $(window).height();

  const contentHeight = (windowHeight - fullOffset + scrollTop) + "px";
  // console.log(contentHeight);

  if (e.matches) {
    // console.log('e matches ! : ', e);
    $('.menuItemCon').css("margin-top", fullOffset);
    $('.nav-background').css({
      "margin-top": fullOffset,
      "height": contentHeight
    });
  }
}






// Initial setup
waitForElement("#qab_container", 300)
  .then(() => {
    calculateHeaderOffset();
  })
  .catch(() => {
    // fallback if QAB is missing
    calculateHeaderOffset();
  });





var touch = window.matchMedia("(pointer: coarse)");

$(mainNavCon).css('display', (e.matches) ? 'block' : 'none');

if(e.matches && !touch.matches) {
  $(menuItemTitles).on("mouseover", (event) => {//hoverable - if screen is minimum 768px & is not a touch screen device
    
    calculateHeaderOffset();
    $(window).on("scroll", closeMenu);

    let selected = $("#menuItem" + event.currentTarget.dataset.id)[0];
    var currentMenu = $(selected).find('.menuItem')[0];

    $('.nav-background').on("mouseenter", closeMenu);

        // $(currentMenu).off();

        if($(selected).hasClass("slide-in")) {

      } else {

        $(".nav-background").addClass('menu-open');

        $('.menuItemTitle.selected').not(event.currentTarget).removeClass("selected");

          $(event.currentTarget).addClass("selected"); 
          $(selected).addClass("slide-in");
          $('.menuItemCon').not($(selected)).css("z-index", 60);
          $(selected).css("z-index", 61);
          $(currentMenu).addClass("activeMenu");

           setTimeout(function () {
                    $('.menuItemCon .menuItem').not($(currentMenu)).removeClass("activeMenu");
                    $('.menuItemCon').not($(selected)).removeClass("slide-in");
                }, 300);
          }        
        
        // $(currentMenu).on("mouseleave", closeMenu);
  });

} else {
    $(menuItemTitles).on("click", function handler(event) { 
     //clickable menu - user is either on a touch screen device or browser width is less than 768px
      // console.log("click!");
      $(window).on("scroll", closeMenu);
      $(".nav-background").addClass('menu-open');

      let selected = $("#menuItem" + event.currentTarget.dataset.id)[0];
      var currentMenu = $(selected).find('.menuItem')[0];

      $(selected).removeClass("slide-out");

      if($(selected).hasClass("slide-in")) {
        // console.log("clicked open menu");
        closeMenu();
      } else {
        console.log("slected does not have slide in class");
         $('.menuItemTitle.selected').not(event.currentTarget).removeClass("selected");
          $(event.currentTarget).addClass("selected"); 
          $(selected).addClass("slide-in");
          $('.menuItemCon').not($(selected)).css("z-index", 60);
           $(selected).css("z-index", 61);
           $(currentMenu).addClass("activeMenu");

           setTimeout(function () {
                    $('.menuItemCon .menuItem').not($(currentMenu)).removeClass("activeMenu");
                    $('.menuItemCon').not($(selected)).removeClass("slide-in");
                }, 300);

      }
          $(menuBackButton).removeClass("invisible");
    })
}



}

function closeMenu() {
  // console.log(" ~~~~~~~~~~~~~~ closing menu ~~~~~~~~~~~~~~~~~~~");
 $('.menuItem.activeMenu').removeClass("activeMenu");
 $('.menuItemTitle.selected').removeClass("selected");
 $(".nav-background").removeClass('menu-open');
 setTimeout(function () {
        $('.menuItemCon.slide-in').removeClass("slide-in");
                }, 300);

 $(window).off("scroll");
 $('.nav-background').off();
}

handleResize(mq);

$('.wayfinding-con .icon-con').on("click", function handler(event){
    window.location.href = event.currentTarget.dataset.link;
});



var bfCards = $("#shopify-section-template--15973919391878__multi_column_6qdcaU").find('li');

$(bfCards).each(function(index) {
    $(this).on("click", function(){
      location.href = cardLinks[index];
    })

});




});